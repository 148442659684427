// eslint-disable-next-line no-restricted-imports
import { Typography, TypographyProps, TypographyVariant } from '@mui/material';

//
//

export type AppTypographyProps = {
  variant?: TypographyVariant;
} & Omit<TypographyProps, 'fontSize' | 'fontWeight' | 'fontStyle'>;

export const AppTypography = ({ children, variant = 'body1', ...props }: AppTypographyProps) => {
  return (
    <Typography {...props} variant={variant}>
      {children}
    </Typography>
  );
};
